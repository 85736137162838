import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarAlt, faMapMarkerAlt, faChevronRight, faChevronDown, faExclamationTriangle, faCheckCircle, faChevronUp, faCaretSquareDown, faPaperclip, faEdit, faTrash, faStar, faPlus, faPlusCircle, faArchive, faSearch, faCloudDownloadAlt, faCheck, faTimes, faComments, faCommentAlt, faClock, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueMask from 'v-mask'

library.add(faCalendarAlt, faMapMarkerAlt, faChevronRight, faCloudDownloadAlt, faChevronDown, faCheckCircle, faChevronUp, faCaretSquareDown, faEdit, faTrash,
  faStar, faPlus, faPlusCircle, faArchive, faSearch, faCheck, faTimes, faExclamationTriangle, faComments, faPaperclip, faClock, faCommentAlt, faLongArrowAltLeft)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueTimepicker)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMask)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
