<template>
  <div id="formDiv" class="container">
    <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3">
      <div class="row">
        <div v-for="item in jsonForm" :key="item.id" v-bind:class="{'col-12': item.columnSize == 12, 'col-6': item.columnSize == 6 }" style="text-align:left;">
          <!---------- Header Image ---------->
          <div v-if="item.type == 'image'" style="margin-bottom: 35px;">
            <div class="row">
              <div class="col-12">
                <img :src="item.imageSource" :alt="item.imageAltText" :width="item.imageWidth" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            <div style="padding-bottom: 25px;">
              <font-awesome-icon icon="exclamation-triangle" style="font-size: 80px;"></font-awesome-icon>
            </div>
            <h4 class="alert-heading">Registration Not Available</h4>
            <p>Online registration is no longer available for this event.</p>
            <!-- <hr>
            <p class="mb-0">If you have any questions please contact Simpson Healthcare at +1 {{phone}}</p> -->
          </div>
        </div>
        <!-- FOOTER -->
        <div id="footer">
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventService from '@/services/event.service'
import Footer from '@/components/Footer.vue'
import moment from 'moment'
export default {
  name: 'not-available',
  components: {
    Footer
  },
  data () {
    return {
      eventId: 0,
      jsonForm: {},
      currentYear: '',
      phone: ''
    }
  },
  methods: {
    showForm: function () {
      this.jsonForm = {}
      const name = this.$route.params.name
      eventService.getEventForm(name).then((response) => {
        this.eventId = response.event_id
        this.getProjectManagerPhone(response.event_id)
        const items = JSON.parse(response.form_elements)
        this.jsonForm = items
      })
    },
    getProjectManagerPhone: function (id) {
      eventService.getProjectManagerByEvent(id).then((response) => {
        this.phone = response.phone.replace(/\./g, '-')
      })
    },
    goBack: function () {
      this.$router.go(-1)
    }
  },
  mounted () {
    this.currentYear = moment().format('YYYY')
    this.showForm()
  }
}
</script>
<style scoped>
#footer {
    height:50px;
    width:100%;
    bottom:0px;
    left:0px;
    position:fixed;
}
</style>
