<template>
  <div>
    <GenerateForm />
    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import GenerateForm from '@/components/GenerateForm.vue'
// import Footer from '@/components/Footer.vue'
import eventService from '@/services/event.service'
export default {
  name: 'Event',
  components: {
    GenerateForm
  },
  data () {
    return {
      eventForm: []
    }
  },
  methods: {
  },
  beforeMount () {
    const name = this.$route.params.name
    eventService.getEventForm(name).then((data) => {
      if (data.response) {
        if (data.response.status === 401) {
          this.$router.push({ name: 'Error404' })
        }
      }
    })
  }
}
</script>
