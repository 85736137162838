<template>
  <div>
  <div id="formDiv" class="container">
    <div class="d-flex flex-column flex-md-row align-items-md-center px-md-4 mb-3">
      <form class="needs-validation" novalidate>
        <div class="row">
          <div v-for="item in jsonForm" :key="item.id" v-bind:class="{'col-12': item.columnSize == 12, 'col-12 col-sm-12 col-md-6': item.columnSize == 6 }" style="text-align:left;">
            <!---------- Input ---------->
            <div class="form-group" v-if="item.type == 'input'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <input :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]">
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!---------- Textarea ---------->
            <div class="form-group" v-if="item.type == 'textarea'">
              <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
              <textarea cols="30" rows="2" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></textarea>
              <div class="invalid-feedback">{{item.error}}</div>
            </div>
            <!---------- Dropdown ---------->
            <div v-if="item.type == 'select'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <select class="form-control" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                  <option value="">{{item.placeholderText}}</option>
                  <option v-for="(choice, index) in item.choices" :key="index">
                    {{ choice }}
                  </option>
                </select>
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.classNme" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
              </div>
            </div>
            <!---------- Checkbox ---------->
            <div v-if="item.type == 'checkbox'">
              <div class="form-group" v-if="item.display === 'standard'">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
                <span class="text-info" v-else> (Select all that apply)</span>
                <div class="clearfix"></div>
                <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                  <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
                </div>
              </div>

              <div class="form-group" v-else>
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label> <span class="text-info" v-if="item.limit === 'Yes'"> (Select up to {{item.limitAllowed}})</span>
                <div class="clearfix"></div>
                <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="checkbox" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                </div>
                <div style="margin-left: 0px;" class="invalid-feedback">{{item.error}}</div>
              </div>

              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
              </div>
            </div>
            <!---------- Radio ---------->
            <div v-if="item.type == 'radio'">
              <div class="form-group" v-if="item.display === 'standard'">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <div class="clearfix"></div>
                <div class="form-check" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                  <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
                </div>
              </div>

              <div class="form-group" v-else>
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <div class="clearfix"></div>
                <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                  <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                  <label class="form-check-label">
                    {{ choice }}
                  </label>
                  <div v-if="item.choices.length == index + 1" class="invalid-feedback">{{item.error}}</div>
                </div>
              </div>

              <!-- Other -->
              <div v-if="item.other === 'Yes'" class="form-group">
                <textarea :name="['other-']+item.id" :id="['other-']+item.className" cols="30" rows="1" class="form-control" v-model="inputForm[item.vModel]"></textarea>
              </div>
            </div>
            <!---------- Switch ---------->
            <div v-if="item.type == 'switch'">
              <div class="form-group">
                <label>{{item.labelText}}</label><br />
                <label class="switch">
                  <input type="checkbox" v-model="inputForm[item.vModel]">
                  <div class="slider round"></div>
                </label>
              </div>

            </div>
            <!---------- Date ---------->
            <div v-if="item.type == 'date'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label>
                <!-- <datepicker format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" :class="checkFormField(item)" @selected="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></datepicker> -->
                <b-form-datepicker today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText"></b-form-datepicker>
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
            <!---------- Time ---------->
            <div v-if="item.type == 'time'">
              <div class="form-group">
                <span class="text-danger" v-show="item.required">* </span><label>{{item.labelText}}</label><br>
                <b-form-timepicker v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText" now-button reset-button></b-form-timepicker>
                <!-- <vue-timepicker format="hh:mm A" input-class="form-control time-background" input-width="100%" :class="checkFormField(item)" @close="checkItem(item)" :placeholder="item.placeholderText" v-model="inputForm[item.vModel]"></vue-timepicker> -->
                <div class="invalid-feedback">{{item.error}}</div>
              </div>
            </div>
            <!---------- Clearfix ---------->
            <div v-if="item.type == 'clearfix'">
              <div class="clearfix"></div>
            </div>

            <!---------- US Address ---------->
            <div v-if="item.type == 'us-address'">
              <div class="row">
                <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-12 col-sm-12 col-md-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                  <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                  <select v-if="item.name == 'State'" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" v-model="inputForm[item.vModel]">
                    <option value="">Select {{item.name}}</option>
                    <option v-for="(choice, index) in item.values" :key="index">
                      {{ choice }}
                    </option>
                  </select>
                  <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                  <div class="invalid-feedback">{{item.error}}</div>
                </div>
              </div>
            </div>

            <!---------- General, Hotel, Flights, Meals ---------->
            <div v-if="item.type == 'general' || item.type == 'hotel' || item.type == 'flights' || item.type == 'meals'">
              <div class="row py-3">
                <div class="col-12">
                  <h5>{{titleCase(item.type)}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-12 col-sm-12 col-md-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                  <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                  <div v-if="item.inputType == 'radio'" >
                    <div class="form-check form-check-inline" v-for="(choice, index) in item.choices" :key="index">
                      <input class="form-check-input" type="radio" :value="choice" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)">
                      <label class="form-check-label">
                        {{ choice }}
                      </label>
                    </div>
                  </div>
                  <input v-else-if="item.inputType == 'birth-date'" type="text" v-mask="'##/##/####'" class="form-control" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText">
                  <b-form-datepicker v-else-if="item.inputType == 'date'" today-button reset-button close-button locale="en" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText"></b-form-datepicker>
                  <b-form-timepicker v-else-if="item.inputType == 'time'" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @input="checkItem(item)" :placeholder="item.placeholderText" now-button reset-button></b-form-timepicker>
                  <!-- <datepicker v-else-if="item.inputType == 'date'" format="MM/dd/yyyy" :bootstrap-styling="true" :clear-button="true" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText"></datepicker>
                  <vue-timepicker v-else-if="item.inputType == 'time'" format="hh:mm A" input-class="form-control time-background" input-width="100%" v-model="inputForm[item.vModel]" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.placeholderText"></vue-timepicker> -->
                  <textarea v-else-if="item.inputType == 'textarea'" cols="30" rows="2" class="form-control" :class="checkFormField(item)" v-model="inputForm[item.vModel]" @change="checkItem(item)" :placeholder="item.placeholderText"></textarea>
                  <input v-else-if="item.clearfix == true" :type="item.inputType" class="form-control" :class="[{'w-50-clearfix': !isMobile()}, checkFormField(item)]" @change="checkItem(item)" v-model="inputForm[item.vModel]" :placeholder="item.name">
                  <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" v-model="inputForm[item.vModel]" :placeholder="item.name">
                  <div class="invalid-feedback">{{item.error}}</div>
                </div>
              </div>
            </div>

            <!---------- Contact ---------->
            <div v-if="item.type == 'contact'">
              <div class="row py-3">
                <div class="col-12">
                  <h5>{{titleCase(item.type)}}</h5>
                </div>
              </div>
              <div class="row">
                <div class="form-group" v-bind:class="{'col-12': item.columnSize == 'col-12', 'col-12 col-sm-12 col-md-6': item.columnSize == 'col-6'}" v-for="(item, index) in item.customFields" :key="index">
                  <span class="text-danger" v-show="item.required">* </span><label>{{item.name}}</label>
                  <input v-if="item.inputType == 'tel'" :type="item.inputType" v-mask="'+1 (###) ###-####'" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                  <input v-else :type="item.inputType" class="form-control" :class="checkFormField(item)" @change="checkItem(item)" :placeholder="item.name" v-model="inputForm[item.vModel]">
                  <div class="invalid-feedback">{{item.error}}</div>
                </div>
              </div>
            </div>

            <!---------- Header Image ---------->
            <div v-if="item.type == 'image'" style="margin-bottom: 35px;">
              <div class="row">
                <div class="col-12">
                  <img :src="item.imageSource" :alt="item.imageAltText" :width="item.imageWidth" class="img-fluid">
                </div>
              </div>
            </div>

            <!---------- Cover Letter ---------->
            <div v-if="item.type == 'word'" style="margin-bottom: 35px;">
              <div class="row">
                <div class="col-12">
                  <div v-html="item.textValue"></div>
                </div>
              </div>
              <div v-show="showError" style="padding: 25px 0px 0px 0px;">
                <div class="alert alert-danger" role="alert">
                  <font-awesome-icon icon="exclamation-triangle" style="font-size: 20px;"></font-awesome-icon><span style="padding-left: 15px;">There were some problems with your form submission. Please check the errors and submit again.</span>
                </div>
              </div>
              <!-- <div v-show="showSuccess" style="padding: 0px;">
                <div class="alert alert-success" role="alert">
                  <font-awesome-icon icon="check-circle" style="font-size: 20px;"></font-awesome-icon><span style="padding-left: 15px;">You have successfully registered for this event</span>
                </div>
              </div> -->
            </div>

            <!---------- Button ---------->
            <div v-if="item.type == 'button'">
              <div class="form-group">
                <button class="btn" @click.stop.prevent="saveEventInfo" :class="[ 'btn-' + item.buttonSize, 'btn-' + item.buttonType ]">{{item.buttonText}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <Footer v-if="formLoaded" />
  </div>
</template>
<script>
import moment from 'moment'
import eventService from '@/services/event.service'
// import Datepicker from 'vuejs-datepicker'
// import VueTimepicker from 'vue2-timepicker'
import { forEach } from 'lodash'
import Footer from '@/components/Footer.vue'
export default {
  name: 'GenerateForm',
  components: {
    // Datepicker,
    // VueTimepicker,
    Footer
  },
  data () {
    return {
      eventId: 0,
      status: '',
      jsonForm: {},
      inputForm: {},
      valid: false,
      inputBlured: false,
      formChecked: false,
      formArray: [],
      showError: false,
      statusRequired: false,
      showSuccess: false,
      formLoaded: false
    }
  },
  methods: {
    titleCase: function (str) {
      str = str.toLowerCase().split(' ')
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
      }
      return str.join(' ')
    },
    isMobile: function () {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      return isMobile
    },
    saveEventInfo: function () {
      this.validate()
      if (this.valid) {
        const final = {}
        final.reg_form = JSON.stringify(this.inputForm)
        final.reg_name = `${this.inputForm.first_name} ${this.inputForm.last_name}`
        final.reg_email = this.inputForm.email
        final.event_id = this.eventId
        final.reg_date = moment().format('YYYY-MM-DD')
        final.reg_status = 'Registered'
        eventService.saveEventForm(final).then((response) => {
          this.showError = false
          // this.showSuccess = true
          // this.showForm()
          // const myDiv = document.getElementById('formDiv')
          // myDiv.scrollTop = 0
          this.$router.push({ name: 'success' })
        })
      }
    },
    validate: function () {
      this.inputBlured = true
      if (this.formArray.length === 0) {
        this.showError = false
        this.valid = true
        this.statusRequired = false
      } else {
        this.showError = true
        this.statusRequired = true
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    checkFormField: function (item) {
      if ((this.inputForm[item.vModel] === '' || this.inputForm[item.vModel].length === 0) && item.required) {
        return {
          'is-invalid': (this.inputForm[item.vModel] === '' || this.inputForm[item.vModel].length === 0) && this.inputBlured && item.required
        }
      } else {
        return {
          'is-valid': this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel].length !== 0
        }
      }
    },
    checkItem: function (item) {
      if ((this.inputForm[item.vModel] !== '' || this.inputForm[item.vModel] !== 0) && item.required) {
        const index = this.formArray.indexOf(item.vModel)
        this.formArray.splice(index, 1)
      }
    },
    showForm: function () {
      this.jsonForm = {}
      const name = this.$route.params.name
      eventService.getEventForm(name).then((response) => {
        const cutOffDate = response.cutoff_date
        if (cutOffDate <= moment().format('YYYY-MM-DD') || response.error === 'registration_limit') {
          this.$router.push({ name: 'not-available' })
        } else {
          this.eventId = response.event_id
          const items = JSON.parse(response.form_elements)
          this.jsonForm = items
          const data = {}
          forEach(items, field => {
            if (field.customFields) {
              field.customFields.forEach(subfield => {
                if (subfield.type === 'checkbox') {
                  data[subfield.vModel] = []
                } else {
                  data[subfield.vModel] = ''
                }
                if (subfield.required) {
                  this.formArray.push(subfield.vModel)
                }
              })
            } else {
              if (field.vModel) {
                if (field.type === 'checkbox') {
                  data[field.vModel] = []
                } else {
                  data[field.vModel] = ''
                }

                if (field.type === 'switch' && data[field.vModel] === '') {
                  data[field.vModel] = false
                }
                if (field.required) {
                  this.formArray.push(field.vModel)
                }
              }
            }
          })
          this.inputForm = data
          this.formLoaded = true
        }
      })
    }
  },
  mounted () {
    this.showForm()
  }
}
</script>
<style scoped>
.w-50-clearfix {
  width: calc(50% - 15px) !important;
}
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
}

.close:focus {
  outline: none;
}

.mce-notification {
  display: none !important;
}

.file-select > .select-button {
  margin: 10px;
  cursor: pointer;
  font-size: 25px;
}

.file-select > input[type="file"] {
  display: none;
}

/*--------------------------------------------------
  Switch
--------------------------------------------------*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
}

.switch input { display: none }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #009370;
}

input:focus+.slider {
  box-shadow: 0 0 1px #009370;
}

input:checked+.slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.slider.round { border-radius: 34px }

.slider.round:before { border-radius: 50% }

.remove-border {
  border-left: 0px !important;
}

.input-group-prepend {
  padding: 10px;
  background-color: #fff;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.required {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.required-border {
  border: 1px solid #dc3545;
  border-radius: 5px;
}

.required-border-valid {
  border: 1px solid #28a745;
  border-radius: 5px;
}
</style>
