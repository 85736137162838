<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="navbar-brand">Vendor Events</div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex right-dropdown">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="float-left" style="margin-top: -10px;">
                <div class="circle">
                  <span class="initials">{{getAccessInitials}}</span>
                </div>
              </div>
              <div class="float-left" style="padding-left: 10px;">
                <h6>{{getAccess}}</h6>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <a href="javascript:void(0);" @click="onLogout"  class="dropdown-item">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div style="padding-top: 15px;">
      <div style="position: fixed; top: 55px; width: 100%;">
        <table class="table" style="background-color: #fff; margin-bottom: 0px;">
          <thead>
            <tr>
              <th width="30%" style="text-align:left;">Event</th>
              <th width="30%" style="text-align:left;">Project Manager</th>
              <th width="10%" style="text-align:left;">Start Date</th>
              <th width="15%" style="text-align:left;">Status</th>
              <th width="15%"></th>
            </tr>
          </thead>
        </table>
        <div style="position: fixed; top: 105px; left: 0px; right: 0px; bottom: 0px; padding-top: 0px; padding-bottom: 0px; background-color: #FFF; overflow-y:auto;">
          <table class="table table-striped">
            <tbody>
              <tr v-for="(item, index) in events" :key="index">
                <td width="30%" style="text-align:left;">{{item.event_name}}</td>
                <td width="30%" style="text-align:left;">{{item.project_manager}}</td>
                <td width="10%" style="text-align:left;">{{item.start_date | formatDate}}</td>
                <td width="15%" style="text-align:left;">
                  <span class="mr-2 badge" :class="{'badge-success': item.status === true , 'badge-danger': item.status === false }">{{getStatusName(item.status)}}</span>
                </td>
                <td width="15%" style="text-align:right;">
                  <a v-if="item.status === true" class="btn btn-outline-primary btn-sm mr-2" :href="downloadRegistrants(item.id)"><font-awesome-icon icon="cloud-download-alt"></font-awesome-icon> Download Excel</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import eventService from '@/services/event.service'
import moment from 'moment'
// @ is an alias to /src
const API = process.env.VUE_APP_API_BASE_URL
export default {
  name: 'vendor-events',
  data () {
    return {
      events: []
    }
  },
  computed: {
    getAccess: function () {
      return JSON.parse(localStorage.getItem('user')).name
    },
    getAccessInitials: function () {
      const first = JSON.parse(localStorage.getItem('user')).first_name.substring(0, 1)
      const last = JSON.parse(localStorage.getItem('user')).last_name.substring(0, 1)
      return first + last
    }
  },
  methods: {
    ...mapActions('accounts', ['logout']),
    onLogout: function () {
      this.logout()
    },
    getVendorEvents: function () {
      const id = JSON.parse(localStorage.getItem('user')).user_id
      eventService.getVendorEvents(id).then((response) => {
        this.events = response
      })
    },
    downloadRegistrants: function (id) {
      return `${API}events/download-registrants/${id}`
    },
    getStatusName: function (item) {
      const formattedItem = JSON.parse(item)
      if (formattedItem === true) {
        return 'Active'
      } else {
        return 'Inactive'
      }
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }
  },
  mounted () {
    this.getVendorEvents()
  }
}
</script>

<style scoped>
.navbar {
  padding: 0 1rem;
}
.bg-light {
  background-color: #006AB6 !important;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover {
  color: #fff;
  padding: 12px;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #fff;
  background-color: #009370;
  padding: 15px;
}

.navbar-light .right-dropdown .active>.nav-link,
.navbar-light .right-dropdown .nav-link.active,
.navbar-light .right-dropdown .nav-link.show,
.navbar-light .right-dropdown .show>.nav-link {
  color: #fff;
  background-color: transparent;
  padding: 15px;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:visited{
  color: #fff;
  padding: 15px;
}

.circle {
  background-color: #333;
  border-radius: 50%;
  height: 40px;
  text-align: center;
  width: 40px;
}

.initials {
  font-size: calc(40px / 2);
  /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(40px / 4);
  /* 25% of parent */
  color: #FFF;
}
</style>
