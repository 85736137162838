<template>
  <div class="container">
    <div class="row">
      <!-- FOOTER -->
      <footer class="col-12">
        <p>&copy; {{currentYear}} Summit Global Health</p>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
export default {
  name: 'Footer',
  data () {
    return {
      currentYear: ''
    }
  },
  methods: {
  },
  mounted () {
    this.currentYear = moment().format('YYYY')
  }
}
</script>
