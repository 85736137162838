<template>
  <div>
    <div class="container">
      <div class="row">
          <div class="col-12">
            <img :src="getLogo()" alt="Simpson Healthcare Logo" width="200">
          </div>
          <div class="col-12" style="text-align:left">
            <h1>Privacy Policy</h1>
          </div>
          <div class="col-12" style="text-align:left;">
            <p>Simpson Healthcare is committed to site visitors' right to privacy and confidentiality of personal information. Simpson Healthcare will not collect personal information without the expressed consent of the visitor. In addition, we will not provide personal information about a visitor to any third party.</p>
            <p>The Simpson Healthcare website collects personal information to determine the effectiveness of our promotional campaigns for the site. Simpson Healthcare has security measures in place to protect the loss, misuse, and alteration of the information under our control. However, no data transmission over the internet can be guaranteed to be completely secure. Accordingly, we cannot ensure or warrant the security of any information that you transmit to us, so you do so at your own risk.</p>
            <p>Anonymous visitor data may be collected and used to help shape and direct creation and maintenance of content, as well as to determine information considered useful by site visitors while on Simpson Healthcare.</p>
            <p>This website is operated in the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to the United States. By using our website, participating in any of our services and/or providing us with your information, you consent to this transfer.</p>
            <p>This website contains links to third party sites. We encourage our users to read the privacy policy of each and every website that collects personally identifiable information. This Privacy Policy applies solely to information collected by this website.</p>
            <p>Your use of this website indicates to us that you have read and accept our privacy practices, as outlined in this Privacy Policy. If you have any questions or concerns regarding this Privacy Policy, please contact <a href="mailto:info@simpsonhealthcare.com">info@simpsonhealthcare.com</a></p>
          </div>
      </div>
    </div>
    <div id="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'privacy-policy',
  components: {
    Footer
  },
  data () {
    return {
    }
  },
  methods: {
    getLogo: function () {
      const images = require.context('@/assets/')
      let fileImage = ''
      fileImage = images('./logo.svg')
      return fileImage
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
#footer {
    height:50px;
    width:100%;
    bottom:0px;
    left:0px;
    position:fixed;
}
</style>
