<template>
  <div>
    <div id="header">
      <img :src="getLogo()" alt="Simpson Healthcare Logo" width="200">
    </div>
    <div id="content" class="container">
      <div class="align-items-center">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <h1 style="font-size:150px;">404</h1>
          </div>
          <div class="col-12 col-md-6" style="text-align:left;">
            <h2>Page Not Found</h2>
            <h4>We can't find the page you're looking for.</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <div id="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import Footer from '@/components/Footer.vue'
export default {
  name: 'Error404',
  components: {
    Footer
  },
  data () {
    return {
      currentYear: ''
    }
  },
  methods: {
    getLogo: function () {
      const images = require.context('@/assets/')
      let fileImage = ''
      fileImage = images('./logo.svg')
      return fileImage
    }
  },
  mounted () {
    this.currentYear = moment().format('YYYY')
  }
}
</script>

<style scoped>
#header {
    height:150px;
    width:100%;
    top:0px;
    left:0px;
    position:fixed;
}

#footer {
    height:50px;
    width:100%;
    bottom:0px;
    left:0px;
    position:fixed;
}
#content {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height:100%;
    padding: 125px 20px 0px 20px;
}
#content > div {
  padding: 25% 0;
}
</style>
