<template>
  <div class="container">
    <div class="row">
        <div class="col-sm-6 col-md-4 col-centered">
            <div v-if="passwordReset.errorStatus" class="alert alert-danger" role="alert"><strong>Error!</strong> {{passwordReset.errorMessage}}</div>
            <div v-if="passwordReset.successStatus" class="alert alert-success" role="alert"><strong>Success!</strong> {{passwordReset.successMessage}}</div>
            <img src="@/assets/logo.svg" height="105" alt="Simpson Star Logo" style="margin-top: 0px; z-index: 9;">
            <div class="account-wall">
                <div style="text-align:center; border-top: 1px solid #ccc; padding: 10px 0px;">
                    <h2 style="font-size: 18px; margin-top: 10px; margin-bottom: 00px; color:#354052; font-weight:300;">Forgot Your Password?</h2>
                </div>
                <form @submit.prevent="handleSubmit" name="forgotPasswordForm" class="form-signin needs-validation" novalidate>
                    <p style="padding-bottom: 10px; text-align:left;">
                        Please enter your email address below to reset your password.
                    </p>
                    <div class="form-item" style="height: 80px; text-align:left; margin-bottom: 15px;">
                      <label>Email address</label>
                      <input type="email" v-model="username" class="form-control" placeholder="Email address" name="email" required>
                      <div class="invalid-feedback">Must be a valid email</div>
                    </div>
                    <button class="btn btn-success btn-block" type="submit">Submit</button>
                </form>
            </div>
            <div class="text-center" style=" padding-top: 10px; font-size: 12px;">
              <router-link :to="{ name: 'login' }" style="color: #7f8ea3; font-weight: 300;">Back to login</router-link>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'forgot-password',
  data () {
    return {
      username: '',
      submitted: false
    }
  },
  methods: {
    ...mapActions('accounts', ['forgotPassword', 'clearPasswordReset']),
    handleSubmit (e) {
      this.submitted = true
      const { username } = this
      // let formValid = false
      const forms = document.getElementsByClassName('needs-validation')
      Array.prototype.filter.call(forms, function (form) {
        if (form.checkValidity() === false) {
          e.preventDefault()
          e.stopPropagation()
        }
        form.classList.add('was-validated')
      })
      if (username) {
        const authData = {
          username: username
        }
        this.forgotPassword(authData)
      }
    }
  },
  computed: {
    ...mapState('accounts', ['passwordReset'])
  },
  mounted () {
    this.clearPasswordReset()
  }
}
</script>

<style scoped>
.col-centered{
    float: none;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 350px;
}
.form-signin
{
    max-width: 330px;
    padding: 20px 20px;
    margin: 0 auto;
}
.form-signin .form-signin-heading, .form-signin .checkbox
{
    margin-bottom: 10px;
    margin-left: 20px;
}
.form-signin .checkbox
{
    font-weight: normal;
}
.form-signin label{
  font-size: 12px;
  color: #7f8fa4;
  font-weight: 300;
  margin-bottom: 0px;
}
.form-signin .form-control
{
    position: relative;
    font-size: 14px;
    height: auto;
    padding: 8px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/* Remove input outline */
.form-signin input {
  outline: none;
  box-shadow:none !important;
}
.form-signin button:focus {
  outline: 0 !important;
}
.form-signin .form-control:focus
{
    z-index: 2;
}
.form-signin input[type="email"]
{
    margin-bottom: 0px;
}
.form-signin input[type="password"]
{
    margin-bottom: 0px;
}
.form-signin button
{
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.form-signin .btn-success {
  background-color: #009370;
}
/* Input Placeholder */
.form-signin ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-weight: 300;
}
.form-signin ::-moz-placeholder { /* Firefox 19+ */
  font-weight: 300;
}
.form-signin :-ms-input-placeholder { /* IE 10+ */
  font-weight: 300;
}
.form-signin :-moz-placeholder { /* Firefox 18- */
  font-weight: 300;
}
.account-wall
{
    margin-top: 5px;
    padding: 0px;
    background-color: #FFF;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.login-title
{
    color: #555;
    font-size: 18px;
    font-weight: 400;
    display: block;
}
.profile-img
{
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.logo-img
{
    height: 96px;
    margin: 0 auto 10px;
    display: block;
}
.col-centered a {
  text-decoration: none;
}
.form-signin p {
  margin-bottom: 0px;
}
.form-signin .form-group {
  margin-bottom: 0px;
}
.form-signin input.ng-invalid.ng-touched {
  border: 1px solid #a94442 !important;
}
.form-signin input.ng-valid.ng-touched {
  border: 1px solid #009370 !important;
}
.ng-invalid .btn-success, .ng-invalid .btn-success:hover {
  background-color: #bbb;
  border-color: #bbb;
}
.error {
  color: #a94442;
  font-size: 12px;
}
</style>
