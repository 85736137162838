import axios from 'axios'
import jwtDecode from 'jwt-decode'
import router from '@/router'

const state = {
  token: null,
  user: null,
  errorStatus: false,
  errorMessage: null,
  passwordReset: {
    errorStatus: false,
    errorMessage: null,
    successStatus: false,
    successMessage: null
  },
  changePasswordError: {
    errorStatus: false,
    errorMessage: null
  }
}

const actions = {
  login ({ commit }, authData) {
    const userToken = btoa(`${authData.username}:${authData.password}`)
    axios.get(`${process.env.VUE_APP_API_BASE_URL}events/vendor-login`, {
      headers: {
        Authorization: `Basic ${userToken}`
      }
    }).then((res) => {
      const userData = jwtDecode(res.data.token)
      const userObject = {
        user_id: userData.id,
        email: userData.email,
        name: userData.first_name + ' ' + userData.last_name,
        first_name: userData.first_name,
        last_name: userData.last_name,
        loggedIn: true
      }
      commit('authUser', {
        token: res.data.token,
        user: userObject
      })
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('user', JSON.stringify(userObject))
      if (userData.first_time) {
        router.push('/change-password')
      } else {
        router.push('/vendor-events')
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('loginFailure', {
          status: true,
          message: error.response.data.message
        })
      }
    })
  },
  forgotPassword ({ commit }, authData) {
    axios.post(`${process.env.VUE_APP_API_BASE_URL}events/vendor-password-reset`, authData).then((res) => {
      commit('resetSuccess', {
        status: true,
        message: 'Password has been reset! Please check your email for a temporary password.'
      })
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('resetFailure', {
          status: true,
          message: error.response.data.message
        })
      }
    })
  },
  clearPasswordReset ({ commit }) {
    commit('clearPasswordReset')
  },
  changePassword ({ commit }, passData) {
    if (passData.newpassword === passData.confirmpassword) {
      commit('changePasswordError', {
        status: false,
        message: null
      })
      axios.put(`${process.env.VUE_APP_API_BASE_URL}events/vendor-change-password/${JSON.parse(localStorage.getItem('user')).user_id}`, passData).then((res) => {
        localStorage.setItem('token', res.data.token)
        router.push('/vendor-events')
      })
    } else {
      commit('changePasswordError', {
        status: true,
        message: 'Passwords do not match!'
      })
    }
  },
  logout ({ commit }) {
    commit('clearAuthData')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    router.replace('/')
  }
}

const mutations = {
  authUser (state, userData) {
    state.token = userData.token
    state.user = userData.user
  },
  loginFailure (state, error) {
    state.errorStatus = error.status
    state.errorMessage = error.message
  },
  resetFailure (state, error) {
    state.passwordReset.errorStatus = error.status
    state.passwordReset.errorMessage = error.message
    state.passwordReset.successStatus = false
    state.passwordReset.successMessage = null
  },
  resetSuccess (state, success) {
    state.passwordReset.errorStatus = false
    state.passwordReset.errorMessage = null
    state.passwordReset.successStatus = success.status
    state.passwordReset.successMessage = success.message
  },
  clearPasswordReset (state) {
    state.passwordReset.errorStatus = false
    state.passwordReset.errorMessage = null
    state.passwordReset.successStatus = false
    state.passwordReset.successMessage = null
  },
  changePasswordError (state, error) {
    state.changePasswordError.errorStatus = error.status
    state.changePasswordError.errorMessage = error.message
  },
  clearAuthData (state) {
    state.token = null
    state.errorStatus = false
    state.errorMessage = null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
