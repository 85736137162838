import { http } from '@/helpers/base-url'

export default {

  getEventForm (name) {
    return http.get(`sgh/event-form/${name}`).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      return error
    })
  },

  saveEventForm (item) {
    return http.post('sgh/event-form', item).then((res) => {
      return res.data
    }).catch((error) => {
      return error
    })
  },

  getVendorEvents (id) {
    return http.get(`sgh/vendor-events/${id}`).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      return error
    })
  },

  getProjectManagerByEvent (id) {
    return http.get(`sgh/${id}/project-manager`).then((res) => {
      const items = res.data
      return items
    }).catch((error) => {
      return error
    })
  }
}
